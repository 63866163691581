import React from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
import cn from 'classnames'
import { Link } from 'gatsby'
import SEO from 'components/seo'

export default withMainLayout(CookiesPolicyPage)

function CookiesPolicyPage() {
  return (
    <div className={cn(css.page, 'container')}>
      <SEO
        title='Правила cookie'
      />
      
      <p align="center">
       <strong>Правила cookie</strong>
      </p>
      <br />
      <p align="justify">
       На сайте <Link to='/'>shabbatshalom.ru</Link> используются файлы cookies. Ниже вы можете узнать больше о том, как мы с ними работаем, и как их можно контролировать.
      </p>

      <p align="justify">
       Когда вы посещаете данный веб-сайт, вы даете согласие на использование файлов cookies в соответствии с настоящим уведомлением об их использовании. Если вы не согласны с этими условиями, пожалуйста, отключите файлы cookies, следуя инструкциям настоящего уведомления об использовании файлов cookies, чтобы они не записывались на вашем устройстве с этого веб-сайта.
      </p>

      <p align="justify">
       Файлы cookies — это небольшие текстовые файлы, которые отправляются на устройство, когда вы посещаете сайт. Файлы cookies затем возвращаются на исходный веб-сайт при каждом последующем визите, или на другой веб-сайт, который распознает файлы cookies. Файлы cookies играют роль памяти для веб-сайта, позволяя веб-сайту узнавать ваше устройство при ваших посещениях. Файлы cookies также могут запомнить ваши предпочтения, улучшить взаимодействие с сайтом, а также адаптировать отображаемую рекламу наиболее выгодным для вас образом.
      </p>

      <p align="justify">
       Вы можете найти более подробную информацию о загруженных файлах cookies, в том числе, о настройках файлов cookies на Вашем устройстве, а также об управлении этими файлами и их удалении по адресу: <a href='https://www.aboutcookies.org/' target='_blank' rel='noreferrer'>www.aboutcookies.org</a>.
      </p>
    </div>
  )
}
